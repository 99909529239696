import React, { useEffect, useState } from 'react';
// import banner1 from '../assets/banner.jpg'; // Import your image
import client from '../constants/client'
import services_data from '../constants/services_data'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import ClientSlider from '../components/ClintSlider';
import AttorneySlider from '../components/AttorneySlider';
import { useLocation, useNavigate } from 'react-router-dom';
import { get_services_by_category, PostData } from '../redux.js/actions';
import { connect, useSelector } from 'react-redux';
import map from '../assets/map.png'
import BlogCard from '../components/Team/BlogCard';
import { Attorney } from '../util/ApplicationData';
import ContactUs from '../components/ContactUs';
const banner1 = "https://images.pexels.com/photos/5467588/pexels-photo-5467588.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
function Home(props) {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState('');

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const validateForm = () => {
    let errors = {};

    // Name validation
    if (!formData.name) {
      errors.name = 'Name is required.';
    }

    // Email validation
    if (!formData.email) {
      errors.email = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      errors.email = 'Invalid email address.';
    }

    // Phone number validation
    if (!formData.number) {
      errors.number = 'Phone number is required.';
    } else if (!/^\+?\d{10,15}$/.test(formData.number)) {
      errors.number = 'Invalid phone number. Must be 10-15 digits long.';
    }

    // Message validation
    if (!formData.message) {
      errors.message = 'Message is required.';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    } else {
      setFormErrors({});
    }

    setIsLoading(true);
    let result = await props.PostData(`api/send_email?id=${props?.userData?.id}`, props?.userData?.token, formData);
    if (result.success) {
      setFormSuccess('Email sent successfully!');
      setFormErrors({
        name: '',
        email: '',
        number: '',
        message: ''
      });
      setFormData({
        name: '',
        email: '',
        number: '',
        message: ''
      });
      setIsLoading(false);
      setTimeout(() => {
        setFormSuccess('');
      }, 10000);
    } else {
      console.log(result)
      setFormSuccess('');
      setFormErrors({ submit: `Failed to send email: ${result.message}` });
      setIsLoading(false);
    }
  };


  const HandleCLick = (item) => {
    const encodedValues = encodeURIComponent(`${item.title}_${item.id}`);
    navigate(`/services_detail/${encodedValues}`);
  }
  return (
    <div className='home_container'>
      <section id='hero' className='home_banner d-flex align-items-center '>
        <Container className='cpt cpb'>
          <Row className='home_inner'>
            <Col lg={6} md={12} sm={12} className='p-2 d-flex justify-content-center align-items-center'>
              <div className='banner-content'>
                <h1>Empowering Your <span>Business Solutions for Success</span></h1>
                <p>
                  Noma Management (Pvt.) Ltd is a well-established and reputable legal and business consultancy firm with a specific focus on the banking sector. With a proven track record of delivering expert guidance and innovative solutions, we have become a trusted partner to numerous financial institutions, banks, and related businesses seeking to navigate the complex regulatory and operational landscape of the banking industry.
                </p>
                <button className='banner-contact-btn' onClick={() => navigate('/about')}>
                  Learn more
                </button>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className=' d-flex justify-content-center our_values'>
              <div className='box-container'>
                <div className='circle' />
                <div className='box'>
                  <div>
                    <h2>Quality</h2>
                    <p>We prioritize delivering high-quality services to our clients, ensuring their satisfaction and success.</p>
                  </div>
                </div>
                <div className='box'>
                  <div>
                    <h2>Reliability</h2>
                    <p>Our commitment to reliability means we consistently deliver our services on time and as promised.</p>
                  </div>
                </div>
                <div className='box'>
                  <div>
                    <h2>Versatility</h2>
                    <p>With a diverse range of services, we can meet the varying needs of clients across different industries.</p>
                  </div>
                </div>
                <div className='box'>
                  <div>
                    <h2>Customer Satisfaction</h2>
                    <p>We strive to understand and exceed our clients' expectations, aiming for their overall satisfaction.</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      {/* all services */}

      <section id='services' className='services_section'>
        <Container className='cpt cpb'>
          <Row>
            <Col className="text-center mx-auto" style={{ maxWidth: '800px' }}>
              <h1>Welcome To NOMA PVT</h1>
              <p className="text-break">Tmply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</p>
            </Col>
          </Row>
          <Row className='mt-2'>
            {services_data.map((item, index) => (
              <Col key={index} xs={12} sm={6} lg={4} className='mt-4'>
                <Card className='custom-card text-center' onClick={() => HandleCLick(item)}>
                  <div className='card-image-container d-flex align-items-center justify-content-center'>
                    <Card.Img variant='top' src={item.image} className='card-image' />
                  </div>
                  <Card.Body className='pt-4'>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text className='pt-3'>
                      {item.shortDescription}
                    </Card.Text>
                  </Card.Body>
                  {/* <Button className='read-more' onClick={() => HandleCLick(item)}></Button> */}
                  <span className='read-more'>Read more...</span>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      {/* all services end */}



      <section id='about-us'>
        <Container className='cpt cpb'>
          <Row className='d-flex align-items-center about_us_container'>
            <Col xs={12} lg={6}>
              <div>
                <h1 className="heading_test">
                  About <span className="color">Us</span>
                </h1>
                <p className="p-lg">At Noma Management, we offer compelling reasons to join us for your service needs. Our commitment to excellence drives everything we do, ensuring that each client receives exceptional service quality. With a team of seasoned professionals dedicated to delivering expertise and support tailored to your unique requirements, we guarantee personalized solutions that cater to your business goals. Utilizing cutting-edge technology and innovative approaches, we provide efficient and effective solutions designed to drive results. Transparent communication is at the heart of our process, keeping you informed and involved every step of the way. Our customer-centric approach ensures your satisfaction, as we actively listen to your needs and provide proactive support. With a proven track record of successful projects and satisfied clients, you can trust us to deliver tangible results and add value to your business. Join us today and experience the difference firsthand.
                </p>
              </div>
            </Col>
            <Col style={{
              paddingRight: 20
            }}>
              <img src='https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' className='reason_image' style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: 5, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }} />
            </Col>
          </Row>
          <Row className='d-flex align-items-center mt-5'>
            <Col style={{
              paddingRight: 20
            }}>
              <img src={require('../assets/images/reson-to-join.png')} className='reason_image' style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: 5, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }} />
            </Col>
            <Col xs={12} lg={6}>

              <div>
                <h1 className="heading_test">
                  Reasons to <span className="color">Join Us</span>
                </h1>
                <p className="p-lg">At Noma Management, we offer compelling reasons to join us for your service needs. Our commitment to excellence drives everything we do, ensuring that each client receives exceptional service quality. With a team of seasoned professionals dedicated to delivering expertise and support tailored to your unique requirements, we guarantee personalized solutions that cater to your business goals. Utilizing cutting-edge technology and innovative approaches, we provide efficient and effective solutions designed to drive results. Transparent communication is at the heart of our process, keeping you informed and involved every step of the way. Our customer-centric approach ensures your satisfaction, as we actively listen to your needs and provide proactive support. With a proven track record of successful projects and satisfied clients, you can trust us to deliver tangible results and add value to your business. Join us today and experience the difference firsthand.
                </p>
              </div>

            </Col>
          </Row>
        </Container>

      </section>




      <section className='section4'>
        <Container className='cpt cpb'>
          <Row>
            <Col className="text-center mx-auto">
              <h1 className='heading'>Happiest Clients</h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mx-auto">
              <ClientSlider
                clientData={client}
              />
            </Col>
          </Row>
        </Container>

      </section>


      <section className='section3'>
        <div className='section3-content'>
          <Container className='cpt cpb'>
            <h1>Satisfied <a className='orange_color'>customers fueling</a><br /> our success.</h1>
            <div className="client-counts mt-5">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="client-count-box">
                    <h2>250+</h2>
                    <p>Happiest Clients</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="client-count-box">
                    <h2>95%</h2>
                    <p>Success Rate</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="client-count-box">
                    <h2>10+</h2>
                    <p>Years of Experience</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>


      <section className='section5' id='team'>
        <Container className='cpt cpb'>
          <Row>
            <Col className="text-center mx-auto">
              <h1 className='heading'>Our Team</h1>
              <p className="text-break">Meet our exceptional team dedicated to delivering excellence in every endeavor. Together, we bring passion and expertise to exceed your expectations.</p>
            </Col>
          </Row>
          <Row style={{}}>
            <Col className="text-center mx-auto">
              <AttorneySlider
                attorneyData={Attorney}
              />
              {/* <BlogCard /> */}
            </Col>
          </Row>
        </Container>
      </section>



      <section className='contact-us' id='contact'>
        <Container className='cpt cpb'>
          <Row>
            <Col className="text-center mx-auto">
              <h1 className='heading'>Contact US</h1>
              <p className="text-break">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati laborum ipsa, a voluptates libero possimus sapiente sint odit iusto blanditiis doloribus.</p>
            </Col>
          </Row>
          <Row className='align-items-center' style={{ paddingTop: 20 }}>
            <Col md={6} className='text-center mb-4 mb-md-0'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.089554371372!2d67.03280650747064!3d24.826610857854057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33d7f0bf2bc6f%3A0x29196c233d885d8f!2sClifton%203%20Talwar%20Display!5e0!3m2!1sen!2s!4v1709580741135!5m2!1sen!2s" width="100%" height="400" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </Col>
            <Col md={6}>
              <div className='about-text'>
                <h2 className='about-text-para-heading'>SEND A MESSAGE</h2>
                <ContactUs buttonClass={"btn-custom"}/>
                {/* <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group controlId='name' className='mb-3'>
                    <Form.Control
                      type='text'
                      name='name'  // Add name attribute
                      placeholder='Name'
                      required
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!formErrors.name}  // Ensure this line is added
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formErrors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='email' className='mb-3'>
                    <Form.Control
                      type='email'
                      name='email'  // Add name attribute
                      placeholder='Email'
                      required
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!formErrors.email}  // Ensure this line is added
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formErrors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='phone' className='mb-3'>
                    <Form.Control
                      type='tel'
                      name='number'
                      placeholder='Phone'
                      required
                      value={formData.number}
                      onChange={handleChange}
                      isInvalid={!!formErrors.number}  // Ensure this line is added
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formErrors.number}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='message' className='mb-3'>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      name='message'  // Add name attribute
                      placeholder='Message'
                      required
                      value={formData.message}
                      onChange={handleChange}
                      isInvalid={!!formErrors.message}  // Ensure this line is added
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formErrors.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {isLoading && <p style={{ color: 'warning' }}>Email is Sending...</p>}
                  {formErrors.submit && <p style={{ color: 'red' }}>{formErrors.submit}</p>}
                  {formSuccess && <p style={{ color: 'green' }}>{formSuccess}</p>}
                  <Button variant='success' type='submit' className='btn-custom'>
                    Send
                  </Button>
                </Form> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
const mapStateToProps = (state) => ({
  services: state.rootReducer.services,
  userData: state.rootReducer.userData
})
const mapStateToDispatch = {
  get_services_by_category: get_services_by_category,
  PostData: PostData
}
export default connect(mapStateToProps, mapStateToDispatch)(Home);
// export default Home
