import React, { useEffect } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function Services(props) {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    const HandleCLick = ({ cat_id, service_id }) => {
        const encodedValues = encodeURIComponent(`${cat_id}_${service_id}`);
        navigate(`/servicearea/${encodedValues}`);
    }
    return (
        <section className='services-section'>
            <Container style={{ paddingBottom: '20px' }}>
                <Row style={{ marginBottom: '40px', marginTop: '50px' }}>
                    <Col className="text-center mx-auto" style={{ maxWidth: '900px' }}>
                        <h1>Our Services</h1>
                        <p className="text-break">offers tailored solutions for all your needs, providing unmatched expertise in diverse fields. From comprehensive consulting to innovative digital solutions, we strive to exceed expectations and drive success. Trust us to deliver excellence in every aspect of service delivery, ensuring your satisfaction and growth.</p>
                    </Col>
                </Row>
                <Row>
                    {props.all_services.map((item, index) => (
                        <>
                            <h3 className='category_name text-center' key={index}>{item.name}</h3>
                            {item.services.map((value, key) => (
                                <Col key={key} xs={12} sm={6} lg={4} className='mb-4'>
                                    <Card className='custom-card-services' style={{ border: 'none', paddingTop: '10px', paddingBottom: '10px' }}>
                                        <div className='card-image-container' style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Card.Img variant='top' src={value.icon} style={{ borderRadius: '250px', width: '250px', height: '250px', objectFit: 'cover', backgroundSize: '100px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', margin: 10 }} />
                                        </div>
                                        <Card.Body style={{ alignItems: 'center' }} className='text-center'>
                                            <Card.Title>{value.title}</Card.Title>
                                            <Card.Text style={{ display: '-webkit-box', WebkitLineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis', '-webkit-box-orient': 'vertical' }}>
                                                {value.description}
                                            </Card.Text>
                                            <Button className='read-more' onClick={() => HandleCLick({ cat_id: item.cat_id, service_id: value.id })}>Read more</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </>
                    ))}
                </Row>
            </Container>
        </section>
    )
}
const mapStateToProps = (state) => ({
    all_services: state.rootReducer.services
})
export default connect(mapStateToProps, null)(Services)