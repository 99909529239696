import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import TimeLineStepper from '../../components/Steppers/TimeLineStepper';
import { get_service_steps } from '../../redux.js/actions';
import { CiUser } from "react-icons/ci";
import { MdAttachment, MdClose, MdSend } from 'react-icons/md';
import QueryOverlay from '../../components/QueryOverlay';

function MyProfile(props) {
    const [instanceData, setInstanceData] = useState(null);

    useEffect(() => {
        if (props?.userData?.id && props?.userData?.token) {
            props.get_service_steps(`api/get_user_service_registrations?id=${props?.userData?.id}`, props?.userData?.token);
        }
    }, [props?.userData?.id, props?.userData?.token]);
    useEffect(() => {
        if (instanceData) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [instanceData]);

    return (
        <section className='profile_section'>
            <Container style={{ marginBottom: '80px', marginTop: '50px' }}>
                {/* <Row className='d-flex align-items-center'>
                        <Col lg={4} md={4} sm={12}>
                            <div className="profile-image-container">
                                <CiUser className="profile-icon" />
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} className='d-flex flex-column justify-content-end'>
                            <div className='d-flex flex-column profile_data'>
                                <p>{props.userData.full_name}</p>
                                <p>{props.userData.email}</p>
                                <p>{props.userData.phone}</p>
                            </div>
                        </Col>
                    </Row> */}
                <p>At nomapvt, we provide a seamless tracking system for users to monitor their service progress in real time. Once you register for a service, each step of your process is tracked and updated dynamically. You can easily follow the status of your service from initial registration through to completion via our user friendly tracking interface. With instant updates from different departments, our system ensures you stay informed about every action taken on your request, giving you transparency and peace of mind.</p>
                <Row className='pt-5'>
                    <h4 className='heading_color text-uppercase'>Track your Registration</h4>
                    {props.service_steps ?
                        props.service_steps.map((service) => (
                            service.instances.map((instance, i) => {
                                let status = instance.status
                                return (
                                    <Accordion className='custom-accordion-header'
                                        // defaultActiveKey={i === 0 ? '0' : null} 
                                        key={i}>
                                        <Accordion.Item eventKey={i.toString()}>
                                            <Accordion.Header>
                                                <div className='d-flex align-items-center w-100'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='text-uppercase' style={{ fontSize: 18 }}>{service.service_name}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <span className={`text-uppercase text-center px-3 font-weight-bold
                                                ${status == 'complete' ? 'text-success' : 'text-warning'}
                                                `}>{status}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center flex-grow-1 justify-content-end'>
                                                        {status != 'complete' &&
                                                            <button className=' query-contact-btn banner-contact-btn m-0' onClick={() => setInstanceData({
                                                                instance_id: instance.instance_id,
                                                                assign_to: instance.assign_to,
                                                                created_by: instance.created_by,
                                                                user_id: instance.user_id,
                                                            })}>
                                                                QUERIES {instance.follow_up_count != 0 &&
                                                                    <Badge bg="secondary">{instance.follow_up_count}</Badge>
                                                                }
                                                                <span className="visually-hidden">unread messages</span>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <TimeLineStepper steps={instance.steps} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            })

                        )
                        ) : null}
                    {props.service_steps ?
                        props.service_steps.length === 0 &&
                        <Col className='pt-5 pb-5 text-center'>
                            <h4 style={{ color: "#666666" }}>Sorry, no registration found</h4>
                        </Col>
                        : null}
                </Row>
            </Container>
            {instanceData != null &&
                <QueryOverlay
                    instance_data={instanceData}
                    onClose={() => setInstanceData(null)}
                />
            }
        </section>
    );
}

const mapStateToProps = (state) => ({
    userData: state.rootReducer.userData,
    service_steps: state.rootReducer.service_steps
});

const mapDispatchToProps = {
    get_service_steps: get_service_steps
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
