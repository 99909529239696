import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import SocialIcons from '../../components/SocialIcons'
import DetailComponent from '../../components/DetailComponent';
import ExplorWork from '../../components/ExplorWork';
import WorkingHours from '../../components/WorkingHours';

function Footer() {
  return (
    <footer className="footer">
      <Container className='footer-container cpt cpb'>
        <Row style={{}}>
          <Col lg={3} md={12} xs={12} className='pt-2'>
            <Card className='footer-card'>
              <Card.Body className='footer-card-body'>
                <Card.Title>
                  <p className='card_title_footer'>ABOUT COMPANY</p>
                </Card.Title>
                <hr className="horizontal-line" />
                <p className='footer-detail-txt'>At Noma Management, we provide tailored service solutions that drive success, combining expertise with cutting-edge technology to meet your business needs.</p>
                <br />
                <p className='footer-detail-txt'>We emphasize clear communication and client satisfaction, delivering proactive support and innovative solutions that add value to your business.</p>
                <SocialIcons
                  rowStyle={{ justifyContent: 'flex-start' }}
                />
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={12} xs={12} className='pt-2'>
            <Card className='footer-card'>
              <Card.Body className='footer-card-body'>
                <Card.Title>
                  <p className='card_title_footer'>KEEP IN TOUCH</p>
                </Card.Title>
                <hr className="horizontal-line" />
                <DetailComponent />
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={12} xs={12} className='pt-2'>
            <Card className='footer-card'>
              <Card.Body className='footer-card-body'>
                <Card.Title>
                  <p className='card_title_footer'>EXPLORE OUR WORK</p>
                </Card.Title>
                <hr className="horizontal-line" />
                <ExplorWork />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={12} xs={12} className='pt-2'>
            <Card className='footer-card'>
              <Card.Body className='footer-card-body'>
                <Card.Title>
                  <p className='card_title_footer'>WORKING HOURS</p>
                </Card.Title>
                <hr className="horizontal-line" />
                <WorkingHours />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
