import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Accordion, Card } from 'react-bootstrap';

function GeneralAsk({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="general-ask container py-4">
      <h2 className="general-ask-heading mb-4 text-center">General Ask</h2>
      <Accordion>
        {data.map((item, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header
              onClick={() => toggleAnswer(index)}
              className="custom-accordion-header"
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <h5 className="mb-0">{item.question}</h5>
                {activeIndex === index ? (
                  <FaMinus className="icon" />
                ) : (
                  <FaPlus className="icon" />
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <p>{item.answer}</p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}

export default GeneralAsk;
