import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import Post from "../../api/Post";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { useDispatch } from "react-redux";
import { LOGIN, USERDATA } from "../../redux.js/actionType";
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [states, setStates] = useState({
    email: "",
    password: "",
    isLoading: false,
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleSubmitForm = async () => {
    const newErrors = {};

    // Validate email
    if (!states.email) {
      newErrors.email = "Email is required";
    }

    if (!states.password) {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => !error);

    if (!isValid) {
      console.log("Form has validation errors. Please correct them.");
    } else {
      setStates((prev) => ({ ...prev, isLoading: true }));
      // setTimeout(() => {
      Post({
        endPoint: "api/login",
        data: {
          email: states.email,
          password: states.password,
          user_type: "user",
        },
      })
        .then((result) => {
          if (result.success) {
            navigate("/");
            dispatch({ type: LOGIN, payload: true });
            dispatch({ type: USERDATA, payload: result.data });
          } else {
            alert(result.message);
            setStates((prev) => ({ ...prev, isLoading: false }));
          }
        })
        .catch((error) => {
          console.log("api error", error);
          setStates((prev) => ({ ...prev, isLoading: false }));
        });
      // }, 2000);
    }
  };
  return (
    <section>
      {states.isLoading && <LoadingSpinner />}
      <div className="container vh-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card text-dark"
              style={{ borderRadius: "1rem", backgroundColor: "#E1E4E1" }}
            >
              <div className="card-body p-4">
                <div className="">
                  <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                  <p className="text-black-50 mb-5">
                    Please enter your username and password!
                  </p>

                  <div className="form-outline form-white mb-2">
                    <label className="form-label" htmlFor="typeEmailX">
                      Email
                    </label>
                    <input
                      type="email"
                      id="typeEmailX"
                      className="form-control form-control-lg form-input"
                      placeholder="Enter Email"
                      value={states.email}
                      onChange={(e) =>
                        setStates((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                    <p className="text-danger">{errors.email}</p>
                  </div>

                  <div className="form-outline form-white mb-2">
                    <label className="form-label" htmlFor="typePasswordX">
                      Password
                    </label>
                    <input
                      type="password"
                      id="typePasswordX"
                      placeholder="Enter Password"
                      className="form-control form-control-lg form-input"
                      value={states.password}
                      onChange={(e) =>
                        setStates((prev) => ({
                          ...prev,
                          password: e.target.value,
                        }))
                      }
                    />
                    <p className="text-danger">{errors.password}</p>
                  </div>

                  <p className="small">
                    <a className="text-black-50" href="#!">
                      Forgot password?
                    </a>
                  </p>

                  <button
                    onClick={() => handleSubmitForm()}
                    className="btn btn-outline-light btn-lg px-5 form-buttons "
                    type="submit"
                  >
                    Login
                  </button>

                  <div className="d-flex justify-content-center text-center mt-4 pt-1">
                    <a href="#!" className="text-dark">
                      <i className="fab fa-facebook-f fa-lg"></i>
                    </a>
                    <a href="#!" className="text-dark">
                      <i className="fab fa-twitter fa-lg mx-4 px-2"></i>
                    </a>
                    <a href="#!" className="text-dark">
                      <i className="fab fa-google fa-lg"></i>
                    </a>
                  </div>
                </div>

                <div>
                  <p className="mb-0">
                    Don't have an account?{" "}
                    <Link to="/signup" className="text-black-50 fw-bold">
                      Sign Up
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
