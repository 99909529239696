const resetCacheAndReload = () => {
    // Clear cache
    caches.keys().then(function (names) {
        for (let name of names) {
            caches.delete(name);
        }
    });

    // Perform a hard reload
    window.location.reload(true);
}

export default resetCacheAndReload;
