import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faFax, faClock } from '@fortawesome/free-solid-svg-icons';

function DetailComponent() {
    return (
        <div className="detail-container">
            <div className="detail-item" style={{}}>
                <FontAwesomeIcon icon={faClock} className="icon" />
                <p className="day">Sat - Mon</p>
            </div>
            <div className="detail-item">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                <p>Suite # 410, 4th Floor, Marine Point,
                Block-9, Clifton, Karachi-75600</p>
            </div>
            <div className="detail-item">
                <FontAwesomeIcon icon={faPhone} className="icon" />
                <p>+92 334 301 3262</p>
            </div>
            <div className="detail-item">
                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                <p>ceo@nomapvt.com</p>
            </div>
        </div>
    );
}

export default DetailComponent;
