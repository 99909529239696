import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes } from '@fortawesome/free-solid-svg-icons';

function WorkingHours() {
    return (
        <div className="working-hours">
            <div className="working-hours-item">
                <p>Monday</p>
                <p>9AM - 8PM</p>
            </div>
            <div className="working-hours-item">
                <p>Tuesday</p>
                <p>9AM - 7PM</p>
            </div>
            <div className="working-hours-item">
                <p>Wednesday</p>
                <p><FontAwesomeIcon icon={faTimes} className="icon" /> Closed</p>
            </div>
            <div className="working-hours-item">
                <p>Thursday</p>
                <p>9AM - 1PM</p>
            </div>
            <div className="working-hours-item">
                <p>Friday</p>
                <p>9AM - 9PM</p>
            </div>
            <div className="working-hours-item">
                <p>Saturday</p>
                <p>8AM - 10PM</p>
            </div>
            <div className="working-hours-item">
                <p>Sunday</p>
                <p><FontAwesomeIcon icon={faTimes} className="icon" /> Closed</p>
            </div>
        </div>
    );
}

export default WorkingHours;
