import axios from 'axios';
import { api_base_url } from '../util/Constant';

const Post = async ({ endPoint, token, data, timeout = 7000, content_Type = "application/json" }) => {
    console.log(endPoint, token, data, content_Type);
    try {
        const response = await axios({
            method: 'post',
            url: api_base_url + endPoint,
            headers: token ? {
                'Authorization': token ? `bearer ${token}` : '',
                'Content-Type': content_Type
            } :
                {
                    'Content-Type': content_Type
                }
            ,
            data: data,
            timeout: timeout,
        });
        console.log("this is response",response)
        return response.data;
    } catch (error) {
        console.log("this is is error",error)
        if (axios.isAxiosError(error)) {
            if (error?.response) {
                if (error.response.status === 403) {
                    console.log("Authorization error: You don't have permission to perform this action.", { type: 'error' });
                } else {
                    console.log("Server error: Please try again later.", { type: 'error' });
                }
            } else if (error?.code === 'ECONNABORTED') {
                console.log("Request timeout: Please try again.", { type: 'error' });
            } else {
                console.log("Network error: Please check your internet connection.", { type: 'error' });
            }
        }
        return error.response;
    }
};

export default Post;
