import React from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SocialIcons from "./SocialIcons.js";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} slick-button slick-button-next`}
            // style={{ ...style }}
            onClick={onClick}
        />
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} slick-button slick-button-prev`}
            // style={{ ...style}}
            onClick={onClick}
        />
    );
}
export default function AttorneySlider({ attorneyData }) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: '60px',
        // centerMode: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    // centerMode: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    centerMode: false
                }
            }
        ]
    };
    return (
        <Slider {...settings}>
            {attorneyData.map((item, index) => (
                <div className="attorney-slider-box" key={index}>
                    <Card key={index} className="attorney-slider-card">
                        <Card.Body className="attorney-slider-card-body">
                            <img src={item.src} alt="Card" className="attorney-slider-box-image" />
                            <div className="attorney-content">
                                <h2>{item.name}</h2>
                                <h6>{item.description}</h6>
                                <p>Noma Management (Pvt.) Limited</p>
                                <SocialIcons />
                            </div>
                        </Card.Body>
                    </Card>

                </div>
            ))}


        </Slider>
    );
}