import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { Col, Container, Row } from 'react-bootstrap';

function SocialIcons({rowStyle}) {
    return (
            <Row className="justify-content-start" style={{flexWrap:'nowrap'}}>
                <Col xs="auto">
                    <a href="#" className="social-icon">
                        <FontAwesomeIcon icon={faFacebookF} size="lg" className='icon-color'/>
                    </a>
                </Col>
                <Col xs="auto">
                    <a href="#" className="social-icon">
                        <FontAwesomeIcon icon={faTwitter} size="lg" className='icon-color'/>
                    </a>
                </Col>
                <Col xs="auto">
                    <a href="#" className="social-icon">
                        <FontAwesomeIcon icon={faLinkedinIn} size="lg" className='icon-color'/>
                    </a>
                </Col>
                <Col xs="auto">
                    <a href="#" className="social-icon">
                        <FontAwesomeIcon icon={faGooglePlusG} size="lg" className='icon-color'/>
                    </a>
                </Col>
            </Row>
    );
}

export default SocialIcons;
