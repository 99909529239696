import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';

function Service_registration() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    return (
        <section className='service_registration'>
            <Container>
                <Row style={{paddingTop:50,paddingBottom:50}}>
                    <Col lg={6} md={6} sm={12}>
                        <img src='https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png' style={{ objectFit: 'fill', width: '100%',maxHeight:500 }} />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <div style={{padding:20}}>
                            <h2>Service name</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Service_registration