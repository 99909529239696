const client = [
    {
        id: 1,
        name: "User 1",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        profileImage: "https://tplinsurance.com/storage/2023/07/TPL-insurance-logo-updatenew.webp",
      },
      {
        id: 2,
        name: "User 2",
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        profileImage: "https://www.bankalhabib.com/app/bahl/assets/addons/bahl/bahl/bahl_theme-theme/resources/images/b1f8b7aac65226d7a3c2034f60a6a2e7.png?v=1584024243",
      },
      {
        id: 3,
        name: "User 3",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        profileImage: require('../assets/partners/habib_matro.png'),
      },
      {
        id: 6,
        name: "User 6",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        profileImage: "https://habib.edu.pk/wp-content/themes/habib/images/logo_new.png",
      },
      {
        id: 7,
        name: "User 7",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        profileImage: "https://iqra.edu.pk/wp-content/uploads/2024/03/LOGO-IU-01.png",
      },
      {
        id: 8,
        name: "User 8",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        profileImage: "https://icilpk.com/wp-content/uploads/2023/06/icillogosmall.jpg",
      },
  ];
  
  export default client;
  