// data.js

const services_data = [
    {
      id: 1,
      title: "Trust Services in Pakistan",
      shortDescription: "Comprehensive solutions for managing and protecting assets through trust arrangements. Expert guidance on creating, administering, and managing trusts.",
      description: [
        "Our Trust Services provide expert solutions for managing and protecting assets through personalized trust arrangements. We understand the intricacies of asset management, estate planning, and legal compliance, and we offer a range of trust services tailored to your specific needs. Whether you are looking to safeguard your assets, ensure a smooth transfer of wealth, or support charitable causes, we are here to help every step of the way.",
        "Why Choose Our Trust Services?",
        {
          type: "list",
          content: [
            "Comprehensive Asset Protection: Secure your wealth for future generations through robust trust structures.",
            "Custom Solutions: We create trusts that are specifically designed to meet your financial, family, and philanthropic goals.",
            "Legal Compliance: Our expert team ensures that your trust complies with all Pakistani laws and regulations.",
            "Tax Efficiency: Minimize taxes and preserve your wealth with well-structured trust arrangements."
          ]
        },
        "Types of Trusts We Offer",
        {
          type: "list",
          content: [
            {
              title: "Revocable Trusts",
              description:
                "Revocable trusts allow you to maintain control over your assets during your lifetime, with the flexibility to make changes or revoke the trust at any time. This is a popular option for individuals looking to manage their assets while ensuring a smooth transfer after their passing."
            },
            {
              title: "Irrevocable Trusts",
              description:
                "Once established, an irrevocable trust cannot be changed or revoked. These trusts provide greater protection from creditors and reduce estate taxes, making them ideal for those looking to safeguard their wealth permanently."
            },
            {
              title: "Charitable Trusts",
              description:
                "Charitable trusts allow you to support causes close to your heart while benefiting from tax exemptions. This option is perfect for individuals who wish to leave a legacy through charitable giving."
            }
          ]
        },
        "Our Trust Registration Process",
        {
          type: "list",
          content: [
            "Initial Consultation and Needs Assessment: We begin by understanding your goals, assets, and intentions. Our experts will assess your financial situation and discuss the best type of trust arrangement for your unique needs.",
            "Drafting Trust Deeds and Legal Documentation: Our legal team will prepare all the necessary trust deeds and documentation. This includes ensuring that the trust structure complies with Pakistani laws and aligns with your long-term objectives.",
            "Trust Registration with Authorities: We handle the complete registration process, submitting all required documents to the relevant authorities, ensuring that your trust is legally recognized and fully compliant.",
            "Asset Transfer and Management: Once your trust is registered, we assist with the transfer of assets into the trust. This includes managing bank accounts, real estate, investments, and other assets to ensure they are properly titled under the trust.",
            "Ongoing Trust Administration: After the trust is established, we provide continuous support for trust administration. This includes handling record-keeping, distributing assets to beneficiaries, and ensuring the trust remains compliant with any changes in law."
          ]
        },
        "Additional Services We Offer",
        {
          type: "list",
          content: [
            "Trust Compliance and Reporting: We ensure that your trust complies with all legal and regulatory requirements in Pakistan. This includes regular audits, filing of necessary documents, and adherence to any changes in trust laws.",
            "Tax Planning: Our tax experts help minimize tax liabilities through strategic planning and effective trust structuring, allowing you to preserve more of your wealth.",
            "Beneficiary Management: We work closely with you to manage beneficiary designations, ensuring that your assets are distributed according to your wishes in a seamless manner."
          ]
        },
        "Benefits of Establishing a Trust",
        {
          type: "list",
          content: [
            "Wealth Preservation: Protect your wealth from unnecessary taxes, creditors, and legal disputes.",
            "Privacy: Trusts offer greater privacy than wills, keeping your financial affairs out of public probate court records.",
            "Control and Flexibility: Maintain control over how your assets are distributed, ensuring that your legacy is managed according to your exact wishes.",
            "Peace of Mind: Safeguard your family's financial future with expert assistance and professional management."
          ]
        },
        "How Our Trust Services Stand Out",
        {
          type: "list",
          content: [
            "Tailored Solutions: Every trust we set up is customized to your needs and goals, whether it’s for family protection or philanthropy.",
            "Expert Legal Support: Our team of seasoned lawyers and financial experts ensure that your trust is legally sound and optimally structured.",
            "Seamless Process: We handle everything from initial consultation to registration, asset management, and ongoing compliance.",
            "Experience You Can Trust: With years of experience helping clients across Pakistan, we are a trusted name in estate and asset management services."
          ]
        },
        "Contact Us Today for a Consultation: Take the first step in securing your assets and ensuring the smooth transfer of wealth with our Trust Services. Contact us today for a personalized consultation to learn how we can help protect your legacy and support your financial goals."
      ],
      image: require('../assets/services/trust_15555787.png'),
      banner:
        "https://img.freepik.com/free-photo/businessman-handshaking-businesswoman-showing-respect-closeup-view-hands-shaking_1163-4679.jpg?t=st=1728840524~exp=1728844124~hmac=50293b874f5d539654e53c264870aab68987f7505cac25021053fff8fe877b1f&w=1480"
    },
    {
      id: 2,
      title: "Legal Services",
      shortDescription: "Expert legal counsel and representation for a wide range of individual and business matters. Tailored solutions in family law, business law, real estate, and more.",
      description: [
        "Our Legal Services cover a wide range of legal needs, from individual legal matters to complex business and governmental issues. We provide expert legal counsel and representation in areas such as family law, business law, real estate law, and more.",
        "Why Choose Our Legal Services?",
        {
          type: "list",
          content: [
            "Expert Legal Representation: Our experienced attorneys offer high-quality representation in a variety of legal areas.",
            "Tailored Legal Solutions: Every case is unique, and we craft personalized strategies for each client, ensuring the best possible outcomes.",
            "Comprehensive Legal Support: From contract disputes to real estate transactions and regulatory compliance, we cover all aspects of the law.",
            "Protect Your Rights: We are dedicated to safeguarding your rights and protecting your legal interests."
          ]
        },
        "Areas of Expertise",
        {
          type: "list",
          content: [
            {
              title: "Family Law",
              description:
                "Our family law services include divorce, child custody, and spousal support. We are committed to helping you navigate through personal family matters with the care and expertise required."
            },
            {
              title: "Business Law",
              description:
                "From business formation and contract negotiations to litigation and regulatory compliance, we provide comprehensive legal support for businesses."
            },
            {
              title: "Real Estate Law",
              description:
                "Our legal team assists in property transactions, lease agreements, and real estate disputes, ensuring all transactions are secure and legally binding."
            },
            {
              title: "Contract Disputes",
              description:
                "We help resolve contract disputes through negotiations, mediation, or litigation to protect your business interests and achieve fair resolutions."
            }
          ]
        },
        "Our Legal Process",
        {
          type: "list",
          content: [
            "Initial Consultation: We assess your case and provide clear advice on how to proceed.",
            "Legal Strategy: Based on the case assessment, we develop a personalized legal strategy that aligns with your goals and needs.",
            "Representation and Documentation: Our team handles all legal documentation and represents you in court or negotiations.",
            "Resolution and Follow-Up: We work towards achieving a favorable outcome and provide continuous support even after the case is resolved."
          ]
        },
        "Additional Legal Support",
        {
          type: "list",
          content: [
            "Regulatory Compliance: Ensure your business operations comply with legal requirements and avoid penalties.",
            "Property Transactions: Secure your real estate investments with sound legal advice and contract reviews.",
            "Litigation Support: Expert representation in court for business or individual legal matters."
          ]
        },
        "Why Our Legal Services Stand Out",
        {
          type: "list",
          content: [
            "Client-Centered Approach: We prioritize your needs, ensuring every solution is tailored to meet your legal goals.",
            "Experienced Legal Team: Our attorneys have years of experience handling complex legal cases, offering you the best possible representation.",
            "Transparent Communication: We keep you informed at every step of the process, ensuring there are no surprises.",
            "Proven Track Record: We have successfully handled numerous legal cases, achieving favorable outcomes for our clients."
          ]
        },
        "Contact Us for Expert Legal Services: Whether you are facing a legal dispute, starting a business, or handling real estate transactions, our team is here to guide you through every step. Contact us today to discuss your legal needs."
      ],
      image: require('../assets/services/justice_16128720.png'),
      banner: "https://img.freepik.com/free-photo/hand-that-holds-hammer-piles-money-written-with-white-chalk-draw-concept_1150-19559.jpg?t=st=1728843618~exp=1728847218~hmac=98659985ffd2c05d55317fc769b2ef15482cc851e75a6b2636590dc66942a711&w=1480"
    },
    {
      id: 3,
      title: "Tech Services",
      shortDescription: "Cutting-edge IT support, software development, and tech consulting. Custom solutions to enhance efficiency and drive innovation.",
      description: [
        "Our Tech Services offer cutting-edge solutions to address your technology needs, including IT support, software development, and technology consulting. We specialize in creating customized tech solutions that enhance efficiency and drive innovation.",
        "Why Choose Our Tech Services?",
        {
          type: "list",
          content: [
            "Customized Tech Solutions: We design solutions tailored to your business to enhance efficiency and drive innovation.",
            "Expert IT Support: Our team offers 24/7 support to ensure your systems run smoothly with minimal downtime.",
            "Innovative Software Development: From bespoke software to enterprise systems, we deliver top-notch development services.",
            "Tech Consulting: Get expert advice on digital transformation, cybersecurity, and technology strategies to future-proof your business."
          ]
        },
        "Areas of Expertise",
        {
          type: "list",
          content: [
            {
              title: "IT Support",
              description: "Comprehensive support for your IT infrastructure, ensuring smooth operations and proactive management of any issues."
            },
            {
              title: "Software Development",
              description: "We create custom software solutions tailored to your business, including web apps, mobile apps, and enterprise-level systems."
            },
            {
              title: "Cybersecurity",
              description: "Our cybersecurity services help protect your systems from potential threats and vulnerabilities, ensuring data security."
            },
            {
              title: "Tech Consulting",
              description: "We provide strategic guidance on how to leverage technology for business growth and competitiveness."
            }
          ]
        },
        "Our Tech Service Process",
        {
          type: "list",
          content: [
            "Initial Consultation: We assess your technology needs and recommend the best solutions.",
            "Design & Development: Our tech experts design and develop solutions tailored to your business.",
            "Deployment & Support: We ensure seamless deployment and offer ongoing support for long-term success.",
            "Continuous Improvement: We monitor performance and continuously improve the technology to keep your business competitive."
          ]
        }
      ],
      image: require('../assets/services/project-management_1087927.png'),
      banner: "https://img.freepik.com/free-photo/html-css-collage-concept-with-person_23-2150062008.jpg?t=st=1728843729~exp=1728847329~hmac=cf1ed7e3c04cebdc8f55eccfc80a3b2cf7cdfc21e63038eb39854d0984a75741&w=1480"
    },
    {
      id: 4,
      title: "Business Services",
      shortDescription: "Support for business growth and management with consulting, financial planning, and operational support to drive success.",
      description: [
        "Our Business Services are designed to support the growth and management of your business. We offer a range of services including business consulting, financial planning, and operational support to help you streamline processes and drive success.",
        "Why Choose Our Business Services?",
        {
          type: "list",
          content: [
            "Comprehensive Business Support: From consulting to operations, we provide full-spectrum services for business growth.",
            "Financial Expertise: Our experts help manage your finances effectively, ensuring profitability and sustainable growth.",
            "Operational Efficiency: We help optimize your business processes to improve productivity and reduce costs.",
            "Strategic Planning: We assist in long-term planning to ensure your business remains competitive and achieves its goals."
          ]
        },
        "Our Areas of Expertise",
        {
          type: "list",
          content: [
            {
              title: "Business Consulting",
              description: "We offer strategic advice and hands-on support to help you address business challenges and grow your company."
            },
            {
              title: "Financial Planning",
              description: "Our financial experts help you manage your finances, from budgeting to investment strategies, ensuring long-term success."
            },
            {
              title: "Operational Support",
              description: "We streamline your operations, providing tools and strategies to enhance productivity and reduce operational costs."
            },
            {
              title: "Risk Management",
              description: "We identify and mitigate risks to protect your business from financial and operational setbacks."
            }
          ]
        },
        "Our Business Service Process",
        {
          type: "list",
          content: [
            "Consultation: We begin by understanding your business needs and goals.",
            "Analysis: We assess your current operations and finances to identify opportunities for improvement.",
            "Strategy Development: Based on our analysis, we create a customized strategy to drive success.",
            "Implementation: We work with your team to implement the strategy and monitor progress for continued success."
          ]
        }
      ],
      image: require('../assets/services/portfolio_3222498.png'),
      banner: "https://img.freepik.com/free-photo/group-diverse-people-having-business-meeting_53876-25060.jpg?t=st=1728843784~exp=1728847384~hmac=4c68f24065395ffaaf66d80a4bb77eff7b0745dc36491928c5ce57282eb55db3&w=1800"
    },
    {
      id: 5,
      title: "Government Services",
      shortDescription: "Specialized support for navigating governmental regulations and processes, including compliance, public policy, and administrative procedures.",
      description: [
        "Our Government Services provide specialized support for navigating governmental regulations and processes. We offer expertise in areas such as regulatory compliance, public policy, and administrative procedures to ensure effective interactions with government bodies.",
        "Why Choose Our Government Services?",
        {
          type: "list",
          content: [
            "Expert Knowledge: Our team has in-depth knowledge of government processes, ensuring smooth navigation of regulations.",
            "Regulatory Compliance: We help ensure that your organization adheres to all government regulations, avoiding penalties and legal complications.",
            "Public Policy Expertise: We assist in understanding and influencing public policy, helping you engage effectively with government bodies.",
            "Administrative Support: From handling permits to filing necessary documentation, we manage the administrative tasks for you."
          ]
        },
        "Our Areas of Expertise",
        {
          type: "list",
          content: [
            {
              title: "Regulatory Compliance",
              description: "Ensure your business complies with government regulations, minimizing legal risks and penalties."
            },
            {
              title: "Public Policy",
              description: "We offer expertise in public policy, helping businesses and organizations engage with lawmakers and government agencies."
            },
            {
              title: "Government Program Navigation",
              description: "We help you navigate government programs, ensuring you take advantage of available resources and opportunities."
            },
            {
              title: "Administrative Procedures",
              description: "We handle administrative tasks, from permits to legal filings, ensuring smooth and efficient processes."
            }
          ]
        },
        "Our Government Service Process",
        {
          type: "list",
          content: [
            "Initial Consultation: We assess your needs and offer solutions to navigate government regulations.",
            "Strategy Development: We create a plan to ensure compliance and effective engagement with government bodies.",
            "Implementation: Our team handles all administrative tasks and ensures successful execution of the strategy.",
            "Continuous Support: We provide ongoing support to ensure you remain compliant and engaged with government processes."
          ]
        }
      ],
      image: require('../assets/services/government_1582292.png'),
      banner: "https://img.freepik.com/free-photo/horizontal-shot-desks-inside-scottish-parliament-building_181624-15462.jpg?t=st=1728843837~exp=1728847437~hmac=c1a9d09e1852a978c086ed10a7237db96ba231b66f378a01121fb8987cd18d6f&w=1480"
    },
    {
      id: 8,
      title: "Tourism Services",
      shortDescription: "Curated tours, destination advice, and travel packages to enhance your travel experiences and explore new destinations.",
      description: [
        "Our Tourism Services are designed to enhance your travel experiences by offering curated tours, destination advice, and travel packages. We provide personalized recommendations and arrangements to help you explore new destinations and enjoy unique experiences.",
        "Why Choose Our Tourism Services?",
        {
          type: "list",
          content: [
            "Personalized Travel Experiences: We curate travel plans tailored to your preferences and interests.",
            "Expert Destination Advice: Our team offers in-depth knowledge of destinations, ensuring you have an enriching travel experience.",
            "All-Inclusive Packages: We provide complete travel packages, covering accommodations, guided tours, and transportation.",
            "Hassle-Free Planning: We handle all aspects of travel planning, from flights to activities, so you can focus on enjoying your trip."
          ]
        },
        "Our Tourism Services Include",
        {
          type: "list",
          content: [
            {
              title: "Curated Tours",
              description: "We offer personalized tours, designed to give you an immersive experience of the destinations you visit."
            },
            {
              title: "Travel Packages",
              description: "Our travel packages include flights, accommodations, and activities, providing a complete travel solution."
            },
            {
              title: "Destination Advice",
              description: "We offer expert advice on destinations, helping you choose the best places to visit based on your interests."
            },
            {
              title: "Custom Itineraries",
              description: "We create customized travel itineraries, allowing you to explore at your own pace and enjoy unique experiences."
            }
          ]
        },
        "Our Travel Planning Process",
        {
          type: "list",
          content: [
            "Consultation: We discuss your travel preferences and offer personalized recommendations.",
            "Booking & Arrangements: We handle all bookings, including flights, accommodations, and activities.",
            "Travel Support: Our team provides ongoing support during your trip, ensuring everything goes smoothly.",
            "Post-Trip Feedback: We value your feedback and use it to improve our services for future trips."
          ]
        }
      ],
      image: require('../assets/services/travel_15897103.png'),
      banner: "https://img.freepik.com/free-photo/tourist-carrying-luggage_23-2151747328.jpg?t=st=1728843889~exp=1728847489~hmac=87034daa2cd3fa0d2c8ecf78435877d6b368928f581e681b0e96c082cf363367&w=1800"
    }
             
];

export default services_data;
