import React, { useState } from 'react'

function Hamburger({ toggleMenu }) {
    return (
        <div className={`hamburger`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default Hamburger