import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Form, Button, Alert } from 'react-bootstrap';
import map from '../assets/map.png';
import { useDispatch } from 'react-redux';
import { PostData } from '../redux.js/actions';

function ContactUs(props) {
    const dispatch = useDispatch()
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        message: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [formSuccess, setFormSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.name) {
            errors.name = 'Name is required.';
        }
        if (!formData.email) {
            errors.email = 'Email is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            errors.email = 'Invalid email address.';
        }
        if (!formData.number) {
            errors.number = 'Phone number is required.';
        } else if (!/^\+?\d{10,15}$/.test(formData.number)) {
            errors.number = 'Invalid phone number. Must be 10-15 digits long.';
        }
        if (!formData.message) {
            errors.message = 'Message is required.';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        } else {
            setFormErrors({});
        }
        setIsLoading(true);
        // Replace this with actual API call
        const result = await dispatch(PostData(`api/send_email?id=${props?.userData?.id}`, props?.userData?.token, formData));
        if (result.success) {
            setFormSuccess('Email sent successfully!');
            setFormData({ name: '', email: '', number: '', message: '' });
        } else {
            setFormErrors({ submit: `Failed to send email: ${result.message}` });
        }
        setIsLoading(false);
        setTimeout(() => {
            setFormSuccess('');
        }, 10000);
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId='name' className='mb-3'>
                <Form.Control
                    type='text'
                    name='name'  // Add name attribute
                    placeholder='Name'
                    required
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!formErrors.name}  // Ensure this line is added
                />
                <Form.Control.Feedback type='invalid'>
                    {formErrors.name}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='email' className='mb-3'>
                <Form.Control
                    type='email'
                    name='email'  // Add name attribute
                    placeholder='Email'
                    required
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!formErrors.email}  // Ensure this line is added
                />
                <Form.Control.Feedback type='invalid'>
                    {formErrors.email}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='phone' className='mb-3'>
                <Form.Control
                    type='tel'
                    name='number'
                    placeholder='Phone'
                    required
                    value={formData.number}
                    onChange={handleChange}
                    isInvalid={!!formErrors.number}  // Ensure this line is added
                />
                <Form.Control.Feedback type='invalid'>
                    {formErrors.number}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='message' className='mb-3'>
                <Form.Control
                    as='textarea'
                    rows={4}
                    name='message'  // Add name attribute
                    placeholder='Message'
                    required
                    value={formData.message}
                    onChange={handleChange}
                    isInvalid={!!formErrors.message}  // Ensure this line is added
                />
                <Form.Control.Feedback type='invalid'>
                    {formErrors.message}
                </Form.Control.Feedback>
            </Form.Group>

            {isLoading && <p style={{ color: 'warning' }}>Email is Sending...</p>}
            {formErrors.submit && <p style={{ color: 'red' }}>{formErrors.submit}</p>}
            {formSuccess && <p style={{ color: 'green' }}>{formSuccess}</p>}
            <Button
                type='submit'
                variant="outline-light"
                className={`${props.buttonClass} custom-btn-outline`}>
                Send
            </Button>
        </Form>
    );
}

export default ContactUs;
