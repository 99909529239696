import axios from 'axios';
import { api_base_url } from '../util/Constant';

const Get = async ({ endPoint, token, custom_url = null, timeout = 7000 }) => {
    try {
        const response = await axios({
            method: 'get',
            url: custom_url != null ? custom_url : api_base_url + endPoint,
            headers: {
                'Authorization': token ? `Bearer ${token}` : '',
            },
            timeout: timeout,
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export default Get;
