import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import services_data from '../constants/services_data';
import { Container, Card, Button, ListGroup } from 'react-bootstrap';
import { FiDownload, FiPhoneCall } from 'react-icons/fi';
import { FaCheckCircle, FaWhatsapp } from 'react-icons/fa';
import { connect } from 'react-redux';
import ContactUs from '../components/ContactUs';
import { generalAskData } from '../util/ApplicationData';
import GeneralAsk from '../components/GeneralAsk';
function Services_detail() {
    const { item } = useParams();
    const [title, id] = decodeURIComponent(item).split('_');
    const [practice, setParactice] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setParactice(services_data.find((item) => item.id == id));
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [id]);
    

    return (
        <section className='practice-section'>
            <Container>
                <div className='row pt-4'>
                    <div className='col-md-8 col-sm-12'>
                        <div className="image-container">
                            {/* Main Service Image */}
                            <img alt='loading image' src={practice?.banner} className="practice-image" />
                            <div className="image-overlay">
                                <h3 className="overlay-title">{practice?.title?.toUpperCase()}</h3>
                            </div>
                        </div>
                        <div className="service-details">
                            {practice?.description?.map((item, index) => {
                                if (typeof item === "string") {
                                    return (
                                        <p className="paractice-desc" key={index}>
                                            {item}
                                        </p>
                                    );
                                } else if (item.type === "list") {
                                    return (
                                        <ul key={index} className="paractice-list">
                                            {item.content.map((listItem, listIndex) => {
                                                if (typeof listItem === "string") {
                                                    return <li key={listIndex} className="list-item">{listItem}</li>;
                                                } else {
                                                    // Render sub-items with title and description
                                                    return (
                                                        <li key={listIndex} className="list-item">
                                                            <span className="list-title">{listItem.title}</span> <span className="list-desc">{listItem.description}</span>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    );
                                }
                            })}
                        </div>

                        <GeneralAsk data={generalAskData} />
                    </div>

                    {/* Right-side Design */}
                    <div className='col-md-4 col-sm-12'>
                        {/* Render All Services with Active Highlight */}
                        <Card className="mb-4 services-card p-2">
                            <Card.Body>
                                <Card.Title className="services-heading pt-2 text-center">Our Services</Card.Title>
                                <ListGroup as="ul" className="services-list">
                                    {services_data.map((service) => (
                                        <ListGroup.Item
                                            key={service.id}
                                            active={service.id == id}
                                            as="li"
                                            className={`service-item ${service.id == id ? 'active-service' : ''}`}
                                            onClick={() => navigate(`/services_detail/${service.title}_${service.id}`)}
                                        >
                                            {service.id == id && <FaCheckCircle className="active-icon" />}
                                            {service.title}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Card>

                        {/* Brochure Section */}
                        <Card className="mb-4 brochure-card">
                            <Card.Body className="text-center align-items-center justify-content-center d-flex flex-column">
                                <Card.Title className="brochure-title">Download Brochure</Card.Title>
                                <Card.Text className="brochure-text">
                                    Discover more about our services by downloading our detailed brochure.
                                </Card.Text>
                                <Button className="custom-btn">
                                    <FiDownload style={{ marginRight: '8px' }} /> Download PDF
                                </Button>
                            </Card.Body>
                        </Card>

                        {/* Let's Talk Section */}
                        <Card className="talk-card mb-4">
                            <Card.Body className="text-center">
                                {/* WhatsApp Icon */}
                                <FaWhatsapp className="whatsapp-icon" />
                                <Card.Title className="talk-title">Let's talk</Card.Title>
                                <Card.Text className="talk-text">
                                    (+92) 334 301 3262<br />
                                    ceo@nomapvt.com
                                </Card.Text>
                                {/* WhatsApp Button */}
                                <Button
                                    variant="outline-light"
                                    className="custom-btn-outline"
                                    href="https://wa.me/923343013262"
                                    target="_blank"
                                    rel="noopener noreferrer"  // Ensures safety by opening in a new tab
                                >
                                    Message on WhatsApp
                                </Button>
                            </Card.Body>
                        </Card>

                        <Card className="talk-card">
                            <Card.Body className="text-center">
                                <Card.Title className="talk-title">Let's talk</Card.Title>
                                <ContactUs/>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        </section >
    );
}

const mapStateToProps = (state) => ({});
const mapStateToDispatch = {};

export default connect(mapStateToProps, mapStateToDispatch)(Services_detail);
