import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaCheckCircle, FaClock } from 'react-icons/fa';

function TimeLineStepper({ steps = [] }) {
    return (
        <VerticalTimeline>
            {steps.map((step, i) => {
                const bgColor = step.completions.length > 0 ? 'green' : '#ccc';
                return (
                    <VerticalTimelineElement
                        key={i}
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: bgColor, color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date={step.completed_at || "Not completed"}
                        iconStyle={{ background: bgColor, color: '#fff' }}
                        icon={step.completions.length > 0 ? <FaCheckCircle /> : <FaClock />}
                    >
                        <h3 className="vertical-timeline-element-title">{step.step_description}</h3>
                        <h4 className="vertical-timeline-element-subtitle">{step.department_name}</h4>
                        {step.completions.length > 0 && (
                            <div>
                                <p>Completed at: {step.completions[0].completed_at}</p>
                                {step.completions[0].files.length > 0 && (
                                    <div className="file-list">
                                        <h5>Files:</h5>
                                        <ul>
                                            {step.completions[0].files.map((file, j) => (
                                                <li key={j}>
                                                    <a href={file.file_path} target="_blank" rel="noopener noreferrer">
                                                        <i className="fas fa-download"></i> {file.file_name}
                                                    </a>
                                                </li>

                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </VerticalTimelineElement>
                );
            })}
        </VerticalTimeline>
    );
}

export default TimeLineStepper;
