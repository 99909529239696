import { toast } from "react-toastify";
import Get from "../../api/Get";
import Post from "../../api/Post";
import { CURRENT_VERSION, SERVICES, SERVICES_STEPS } from "../actionType";
import resetCacheAndReload from "../../funtions/My_funtions";

export const setFormData = (data) => ({
  type: 'ADD_SERVICE',
  payload: data,
});


export const get_services_by_category = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint });
      if (response?.success) {
        dispatch({ type: SERVICES, payload: response.data })
        return true
      } else {
        dispatch({ type: SERVICES, payload: [] })
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};
export const get_service_parameters = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        return response.data
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  };
};
export const register_service = (endPoint, data, token) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, data: data, token: token, content_Type: 'multipart/form-data' });
      if (response?.success) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return false
    }
  };
};
export const get_service_steps = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response?.success) {
        dispatch({ type: SERVICES_STEPS, payload: response.data })
        return true
      } else {
        dispatch({ type: SERVICES_STEPS, payload: [] })
        return false
      }
    } catch (error) {
      dispatch({ type: SERVICES_STEPS, payload: [] })
      return false
    }
  };
};
export const get_current_version = (endPoint, current_version) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint });
      if (response?.success) {
        if (response.data.version_number != current_version) {
          dispatch({ type: CURRENT_VERSION, payload: response.data.version_number })
          resetCacheAndReload()
        }
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const getUserFlowUps = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response.data
      } else {
        return []
      }
    } catch (error) {
      return false
    }
  };
};


export const AddUserQueries = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data, content_Type: "multipart/form-data" });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};

export const PostData = (endPoint, token, data) => {
  return async (dispatch) => {
    try {
      const response = await Post({ endPoint: endPoint, token: token, data: data });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};
export const GetData = (endPoint, token) => {
  return async (dispatch) => {
    try {
      const response = await Get({ endPoint: endPoint, token: token });
      if (response.success) {
        return response
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  };
};