import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../pages/Layout/Header";
import Home from "../pages/Home";
import NoPage from "../pages/NoPage";
import About from "../pages/About";
import '../App.css'
import Footer from "../pages/Layout/Footer";
import Login from "../pages/account/Login";
import SignUp from "../pages/account/SignUp";
import { connect } from "react-redux";
import Services from "../pages/Services";
import Services_detail from "../pages/Services_detail";
import Service_registration from "../pages/service_registration/Service_registration";
import My_profile from "../pages/Profile/My_profile";
import { get_current_version } from "../redux.js/actions";
import { useEffect } from "react";
import socket from "../config/SockerConfig";
function Index(props) {
    useEffect(() => {
        if (props.login) {
            socket.on('connect', () => {
                let data = { id: props.userData.id, socket_id: socket.id };
                socket.emit('update_socket_id', data);
            });
            socket.on('connect_error', (error) => {
                console.error('Socket connection error:', error);
            });

            socket.on('disconnect', (reason) => {
                console.warn('Socket disconnected:', reason);
            });
            socket.emit('some_events', "asldjfalsdjfalsdj");

            return () => {
                socket.off('connect_error');
                socket.off('disconnect');
            };
        }
    }, [props.login])
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={
                    <>
                        <Header />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="about" element={<About />} />
                            <Route path="services" element={<Services />} />
                            {props.login && <Route path="my_profile" element={<My_profile />} />}
                            <Route path="service_registration" element={<Service_registration />} />
                            <Route path="services_detail/:item" element={<Services_detail />} />
                            <Route path="*" element={<NoPage />} />
                        </Routes>
                        <Footer />

                    </>
                } />
                {!props.login && (
                    <>
                        <Route path="Login" element={<Login />} />
                        <Route path="signUp" element={<SignUp />} />
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
}

const mapStateToProps = (state) => ({
    login: state.rootReducer.login,
    userData: state.rootReducer.userData,
    current_version: state.rootReducer.current_version
})
const mapStateToDispatch = {
    get_current_version: get_current_version
}

export default connect(mapStateToProps, mapStateToDispatch)(Index)
