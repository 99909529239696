import React, { useState } from 'react'
import logo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import Hamburger from '../../components/Hamburger';
import { connect, useDispatch, useSelector } from 'react-redux';
import { LOGIN } from '../../redux.js/actionType';
import plachoderImage from '../../assets/user.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Container, Row } from 'react-bootstrap';
function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector(l => l.rootReducer.login)
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }
  const HandleCLick = ({ cat_id, service_id }) => {
    const encodedValues = encodeURIComponent(`${cat_id}_${service_id}`);
    navigate(`/servicearea/${encodedValues}`);
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };
  const logout = () => {
    dispatch({ type: LOGIN, payload: false })
    navigate('/login')
  }
  return (

    <header className={`header ${isOpen ? 'open' : ''}`}>
      <Container className='d-flex header_container'>
        <div className='app_logo'>
          <Link className='shadow1' to="/" onClick={closeMenu}>
            <img alt='nomaPVt' src={logo} className='application-logo' />
          </Link>
          <Hamburger
            toggleMenu={toggleMenu}
          />
        </div>

        <nav>
          <ul>
            <li><Link onClick={closeMenu} className='shadow1' to="/#hero">Home</Link></li>
            <li className='shadow1 services'>
              <Link onClick={closeMenu} to='/#services'>Services</Link>
              <div className='dropdown-container'>
                <div className='d-flex'>
                  {props?.services?.map((c, c_index) => {
                    return (
                      <div className='category-container' key={c_index}>
                        <h6 className='category-heading'>{c.name}</h6>
                        <ul className='category-items'>
                          {c.services.map((item, item_index) => (
                            <div key={item_index} className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => HandleCLick({ cat_id: c.cat_id, service_id: item.id })}>
                              <FontAwesomeIcon icon={faArrowRight} className="icon" style={{ paddingRight: 10 }} />
                              <div>
                                <a style={{ whiteSpace: 'nowrap' }}>{item.title}</a>
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )
                  })}
                </div>
                {/* <Row className='d-flex text-end' style={{ paddingBottom: 10, paddingRight: 20, cursor: 'pointer' }} onClick={() => navigate("/services")}>
                  <a style={{ fontSize: 14 }}>View aLL</a>
                </Row> */}
              </div>
            </li>

            <li><Link onClick={closeMenu} className='shadow1' to="/#about-us">About Us</Link></li>
            <li><Link onClick={closeMenu} className='shadow1' to="/#team">Our Team</Link></li>
            <li><Link onClick={closeMenu} className='shadow1' to="/#contact">Contact us</Link></li>

          </ul>
          <ul>
            {!isLogin ?
              <Link onClick={closeMenu} to="login" className='header-responsive-btn'>
                <button className='header_login_btn'>
                  <a>Login</a>
                </button>
              </Link>
              :
              <div className='header-responsive-btn header_profile_container'>
                <div className='profile_header_image'>
                  <img src={plachoderImage} className='header_profile_image' />
                </div>
                <div className='profile_dropdown_container'>
                  <div className='dropdown_innerContainer'>
                    {props.userData != null &&
                      <div className='header_profile_section'>
                        <h6 className='user_name'>{props.userData.full_name}</h6>
                        <span style={{fontSize:12}}>{props.userData.email}</span><br/>
                        <span style={{fontSize:12}}>{props.userData.phone}</span>
                      </div>
                    }
                    <li style={{ whiteSpace: 'nowrap' }} onClick={() => navigate('/My_profile')}>Track Progress</li>
                    {/* <li>Notification</li> */}
                    <li style={{ color: 'red' }} onClick={logout}>Logout</li>
                  </div>
                </div>
              </div>
            }
          </ul>
        </nav>
      </Container>
    </header>
  )
}
const mapStateToProps = (state) => ({
  services: state.rootReducer.services,
  userData: state.rootReducer.userData
})
const mapStateDispatch = {

}
export default connect(mapStateToProps, mapStateDispatch)(Header)
