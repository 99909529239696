import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import Post from "../../api/Post";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
function SignUp() {
  const navigate = useNavigate();
  const [states, setStates] = useState({
    email: "",
    // phone: "",
    first_name: "",
    phone: "",
    last_name: "",
    password: "",
    con_password: "",
    isLoading: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    // phone: '',
    first_name: "",
    last_name: "",
    password: "",
    con_password: "",
  });
  const handlSubmitForm = async () => {
    const newErrors = {};

    // Validate email
    if (!states.email) {
      newErrors.email = "Email is required";
    }

    // Validate phone number
    if (!states.phone || isNaN(states.phone) || states.phone.length !== 11) {
      newErrors.phone = 'Invalid phone number';
    }
    // Validate password
    if (!states.first_name) {
      newErrors.first_name = "First name is required";
    }
    if (!states.last_name) {
      newErrors.last_name = "Last name is required";
    }
    if (!states.password) {
      newErrors.password = "Password is required";
    }

    // Validate confirm password
    if (states.password !== states.con_password) {
      newErrors.con_password = "Passwords do not match";
    }

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => !error);

    if (!isValid) {
      console.log("Form has validation errors. Please correct them.");
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setStates((prev) => ({ ...prev, isLoading: true }));
      // setTimeout(() => {
      Post({
        endPoint: "api/create_account_web",
        data: {
          full_name: states.first_name + " " + states.last_name,
          phone: states.phone,
          email: states.email,
          password: states.password,
        },
      })
        .then((result) => {
          if (result.success) {
            setStates((prev) => ({ ...prev, isLoading: false }));
            alert(result.message);
            navigate("/login");
          } else {
            alert(result.message);
            setStates((prev) => ({ ...prev, isLoading: false }));
          }
        })
        .catch((error) => {
          console.log(error);
          setStates((prev) => ({ ...prev, isLoading: false }));
        });
      // }, 2000);
    }
  };
  return (
    <section >
      {states.isLoading && <LoadingSpinner />}
      <div className="container vh-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card text-dark"
              style={{ borderRadius: "1rem", backgroundColor: "#E1E4E1" }}
            >
              <div className="card-body p-5 p-md-4">
                <div className="">
                  <h2 className="fw-bold mb-2 text-uppercase">Sign Up</h2>
                  <p className="text-black-50 mb-5">
                    Please enter your details to create an account.
                  </p>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-outline form-white mb-2">
                        <label className="form-label" htmlFor="typeFirstNameX">
                          First Name
                        </label>
                        <input
                          placeholder="Enter First Name"
                          type="text"
                          id="typeFirstNameX"
                          className="form-control form-control-lg form-input"
                          value={states.first_name}
                          onChange={(e) =>
                            setStates((prev) => ({
                              ...prev,
                              first_name: e.target.value,
                            }))
                          }
                        />
                        <p className="text-danger">{errors.first_name}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-outline form-white mb-2">
                        <label className="form-label" htmlFor="typeLastNameX">
                          Last Name
                        </label>
                        <input
                          placeholder="Enter Last Name"
                          type="text"
                          id="typeLastNameX"
                          className="form-control form-control-lg form-input"
                          value={states.last_name}
                          onChange={(e) =>
                            setStates((prev) => ({
                              ...prev,
                              last_name: e.target.value,
                            }))
                          }
                        />
                        <p className="text-danger">{errors.last_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="form-outline form-white mb-2">
                    <label className="form-label" htmlFor="typePhoneX">
                      Phone
                    </label>
                    <input
                      // autoComplete="new-phone" // Helps prevent autofill for phone numbers
                      placeholder="Enter Phone"
                      type="tel"  // Use 'tel' for phone number input
                      id="typePhoneX"  // Updated to reflect the phone field
                      className="form-control form-control-lg form-input"
                      value={states.phone}
                      maxLength={11}
                      onChange={(e) =>
                        setStates((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }))
                      }
                    />
                    <p className="text-danger">{errors.phone}</p> {/* Update error handling for phone */}
                  </div>


                  <div className="form-outline form-white mb-2">
                    <label className="form-label" htmlFor="typeEmailX">
                      Email
                    </label>
                    <input
                      autoComplete="new-email"
                      placeholder="Enter Email"
                      type="email"
                      id="typeEmailX"
                      className="form-control form-control-lg form-input"
                      value={states.email}
                      onChange={(e) =>
                        setStates((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                    <p className="text-danger">{errors.email}</p>
                  </div>

                  <div className="form-outline form-white mb-2">
                    <label className="form-label" htmlFor="typePasswordX">
                      Password
                    </label>
                    <input
                      autoComplete="new-password"
                      type="password"
                      id="typePasswordX"
                      placeholder="Enter Password"
                      className="form-control form-control-lg form-input"
                      value={states.password}
                      onChange={(e) =>
                        setStates((prev) => ({
                          ...prev,
                          password: e.target.value,
                        }))
                      }
                    />
                    <p className="text-danger">{errors.password}</p>
                  </div>

                  <div className="form-outline form-white mb-2">
                    <label className="form-label" htmlFor="typePasswordX">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      id="typePasswordX"
                      className="form-control form-control-lg form-input"
                      placeholder="Enter Confirm Password"
                      value={states.con_password}
                      onChange={(e) =>
                        setStates((prev) => ({
                          ...prev,
                          con_password: e.target.value,
                        }))
                      }
                    />
                    <p className="text-danger">{errors.con_password}</p>
                  </div>

                  <button
                    onClick={() => handlSubmitForm()}
                    className="btn btn-outline-light btn-lg px-5 form-buttons "
                    type="submit"
                  >
                    Sign Up
                  </button>

                  <div className="d-flex justify-content-center text-center mt-4 pt-1">
                    <a href="#!" className="text-dark">
                      <i className="fab fa-facebook-f fa-lg"></i>
                    </a>
                    <a href="#!" className="text-dark">
                      <i className="fab fa-twitter fa-lg mx-4 px-2"></i>
                    </a>
                    <a href="#!" className="text-dark">
                      <i className="fab fa-google fa-lg"></i>
                    </a>
                  </div>
                </div>

                <div>
                  <p className="mb-0">
                    Already have an account?{" "}
                    <Link to="/login" className="text-black-50 fw-bold">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
