import { CURRENT_VERSION, LOGIN, SERVICES, SERVICES_STEPS, USERDATA } from "../actionType";

// reducers/formReducer.js
const initialState = {
  login: false,
  userData: null,
  services: [],
  service_steps: [],
  current_version: null
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case USERDATA:
      return {
        ...state,
        userData: action.payload,
      };
    case SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case SERVICES_STEPS:
      return {
        ...state,
        service_steps: action.payload,
      };
    case CURRENT_VERSION:
      return {
        ...state,
        current_version: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
