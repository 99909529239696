import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { MdAttachment, MdClose, MdSend } from 'react-icons/md';
import { connect } from 'react-redux';
import { getUserFlowUps, AddUserQueries } from '../redux.js/actions';
import socket from '../config/SockerConfig';

const QueryOverlay = ({ userData, getUserFlowUps, AddUserQueries, isQueryOpen, onClose, instance_data }) => {
    const [message, setMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [allMessages, setAllMessages] = useState([]);
    const scrollableContentRef = useRef(null);
    useEffect(() => {
        GetUserFlowUps();
    }, [])

    useEffect(() => {
        if (scrollableContentRef.current) {
            scrollableContentRef.current.scrollTop = scrollableContentRef.current.scrollHeight;
        }
    }, [allMessages]);

    const GetUserFlowUps = async () => {
        let result = await getUserFlowUps(`api/get_user_flowUps?id=${userData.id}&instance_id=${instance_data?.instance_id}`, userData.token)
        if (result) {
            setAllMessages(result)
            let unRead = result.filter((res) => res.is_read == false && res.send_to == userData.id).map(i => i.flowup_id)
            if (unRead.length > 0) {
                socket.emit('notification_status', unRead);
            }
        }
    }

    const handleFileChange = (event) => {
        setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)]);
    };

    const handleFileRemove = (index) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    };

    const handleFileClick = (filePath) => {
        const fileName = filePath.split('/').pop();
        const fileExtension = fileName.split('.').pop().toLowerCase();

        const viewableExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp'];

        if (viewableExtensions.includes(fileExtension)) {
            window.open(filePath, '_blank');
        } else {
            const link = document.createElement('a');
            link.href = filePath;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const SendMessage = async () => {
        if (!message) return;

        const newMessage = {
            message: message,
            user_type: 'user',
            file_paths: selectedFiles.map(file => ({
                file_name: file.name,
                file_path: URL.createObjectURL(file)
            }))
        };

        setAllMessages(prevMessages => [...prevMessages, newMessage]);

        const formData = new FormData();
        formData.append('instance_id', instance_data?.instance_id);
        formData.append('message', message);
        formData.append('user_type', 'user');
        formData.append('noti_type', 'query_noti');
        formData.append('full_name', userData.full_name);
        formData.append('email', userData.email);
        for (const file of selectedFiles) {
            formData.append('files', file);
        }
        AddUserQueries(`api/add_user_flowUp?id=${userData.id}`, userData.token, formData);
        setMessage('');
        setSelectedFiles([]);
    };


    return (
        <div className="query-overlay">
            <div className='overlay-content d-flex flex-column'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h5 className='m-0'>Queries</h5>
                    <MdClose size={24} className='cursor-pointer' onClick={onClose} />
                </div>
                <div className='scrollable-content flex-grow-1 mb-3' ref={scrollableContentRef}>
                    {allMessages.map((msg, index) => (
                        <div key={index} className={`message ${msg.user_type === 'user' ? 'user-message' : 'admin-message'}`}>
                            <div className="message-content">
                                <p>{msg.message}</p>
                                {msg.file_paths.length > 0 && (
                                    <div className='file-attachments'>
                                        {msg.file_paths.map((file, i) => (
                                            <div key={i} className='file-attachment' onClick={() => handleFileClick(file.file_path)}>
                                                <MdAttachment size={20} className='file-icon' />
                                                <span className='file-name'>{file.file_name}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="file-list">
                    {selectedFiles.map((file, index) => (
                        <div key={index} className="file-item d-flex justify-content-between mb-2">
                            <span>{file.name}</span>
                            <MdClose size={20} className='cursor-pointer' onClick={() => handleFileRemove(index)} />
                        </div>
                    ))}
                </div>
                <div className="query-input d-flex">
                    <Form.Control
                        type="text"
                        placeholder="Type your message..."
                        className='me-2'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <input
                        type="file"
                        multiple
                        id="file-upload"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <Button variant="outline-secondary" className='me-2' onClick={() => document.getElementById('file-upload').click()}>
                        <MdAttachment size={20} />
                    </Button>
                    <Button variant="primary" onClick={() => SendMessage()}>
                        <MdSend size={20} />
                    </Button>
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userData: state.rootReducer.userData,
    service_steps: state.rootReducer.service_steps,
});

const mapDispatchToProps = {
    getUserFlowUps: getUserFlowUps,
    AddUserQueries: AddUserQueries
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryOverlay);;
