import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function About() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <section className='about-us'>
        <div className='about-us-content text-center'>
          <h2>LEARN ABOUT OUR MISSION</h2>
        </div>
      </section>
      <Container className='my-5'>

        <Row className='align-items-center about-row'>
          <Col md={6} className='text-center mb-4 mb-md-0'>
            <img
              src='https://images.unsplash.com/photo-1500576992153-0271099def59?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80'
              alt='Mission'
              className='img-fluid'
            />
          </Col>
          <Col md={6}>
            <div className='about-text'>
              <h2 className='about-text-para-heading'>INTRODUCTION</h2>
              <p className='about-text-para'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi
              </p>
            </div>
          </Col>
        </Row>

        <Row className='align-items-center about-row' style={{marginTop:'100px'}}>
          <Col md={6}>
            <div className='about-text'>
              <h2 className='about-text-para-heading'>FOUNDING ATTORNEYS AND PARTNERS</h2>
              <p className='about-text-para'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi
              </p>
            </div>
          </Col>
          <Col md={6} className='text-center mb-4 mb-md-0'>
            <img
              src='https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80'
              alt='Mission'
              className='img-fluid'
            />
          </Col>
        </Row>

        <Row className='align-items-center about-row' style={{ marginTop: '100px' }}>
          <Col md={6} className='text-center mb-4 mb-md-0'>
            <img
              src='https://images.unsplash.com/photo-1521791055366-0d553872125f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80'
              alt='Mission'
              className='img-fluid'
            />
          </Col>
          <Col md={6}>
            <div className='about-text'>
              <h2 className='about-text-para-heading'>LEGAL APPROACH AND PHILOSOPHY</h2>
              <p className='about-text-para'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi
              </p>
            </div>
          </Col>
        </Row>

        <Row className='align-items-center about-row' style={{ marginTop: '100px' }}>
          <Col md={6}>
            <div className='about-text'>
              <h2 className='about-text-para-heading'>PRIVACY AND CONFIDENTIALITY</h2>
              <p className='about-text-para'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vivamus sed augue ac eros consectetur fermentum. Nulla
                facilisi
              </p>
            </div>
          </Col>
          <Col md={6} className='text-center mb-4 mb-md-0'>
            <img
              src='https://images.unsplash.com/photo-1633265486064-086b219458ec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
              alt='Mission'
              className='img-fluid'
            />
          </Col>
        </Row>
        
      </Container>
    </div>
  );
}

export default About;
