import React from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} slick-button slick-button-next`}
            // style={{ ...style }}
            onClick={onClick}
        />
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} slick-button slick-button-prev`}
            // style={{ ...style}}
            onClick={onClick}
        />
    );
}
export default function SimpleSlider({ clientData }) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: '60px',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            }
        ]
    };
    return (
        <Slider {...settings}>
            {clientData.map((item, index) => (
                <div className="slider-box" key={index}>
                    <Card key={index} className="slider-card">
                        <Card.Body className="slider-card-body">
                            <Image src={item.profileImage} className="slider-profile-image" />
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </Slider>
    );
}