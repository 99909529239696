import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import services_data from '../constants/services_data';
import { useNavigate } from 'react-router-dom';

function ExplorWork() {
    const navigate = useNavigate();
    const handleItemClick = (item) => {
        const encodedValues = encodeURIComponent(`${item.title}_${item.id}`);
        navigate(`/practice/${encodedValues}`);
    };
    return (
        <div className="detail-container">
            {services_data.map((item, index) => {
                return (
                    <div className="detail-item" onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }} key={index}>
                        <FontAwesomeIcon icon={faArrowRight} className="icon" />
                        <p className="day">
                            {item.title}
                        </p>
                    </div>
                )
            })}
        </div>
    );
}

export default ExplorWork;
